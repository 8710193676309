var url = config.APPSTORE.das_url+'/file/';
window._newFiles = {apk:[],img:[]};
window._oldFiles = {apk:[],img:[]};
window.isControlMode=true;


Array.prototype.remove=function(obj){
    for(var i =0;i <this.length;i++){
        var temp = this[i];
        if(!isNaN(obj)){
            temp=i;
        }
        if(temp == obj){
            for(var j = i;j <this.length;j++){
                this[j]=this[j+1];
            }
            this.length = this.length-1;
        }
    }
}

//URL解析函数
function parseURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return {
        source: url,
        protocol: a.protocol.replace(':', ''),
        host: a.hostname,
        port: a.port,
        query: a.search,
        params: (function() {
            var ret = {},
            seg = a.search.replace(/^\?/, '').split('&'),
            len = seg.length,
            i = 0,
            s;
            for (; i < len; i++) {
                if (!seg[i]) {
                    continue;
                }
                s = seg[i].split('=');
                ret[s[0]] = s[1];
            }
            return ret;
        })(),
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
        hash: a.hash.replace('#', ''),
        path: a.pathname.replace(/^([^\/])/, '/$1'),
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
        segments: a.pathname.replace(/^\//, '').split('/')
    };
}


//左侧导航菜单
function left_menu(obj){
    var b = $(obj).next('.nav-sidebar')
    var img = $(obj).find('img')
    if(b.css('display')=='block'){
        img.attr("src",'image/arrow_2.gif');
        b.css('display','none')
    }else{
        img.attr("src",'image/arrow_1.gif');
        b.css('display','block')
    }

}


function createCORSRequest(method, url) {
  var xhr = new XMLHttpRequest();
  if ("withCredentials" in xhr) {
    // XHR for Chrome/Firefox/Opera/Safari.
    xhr.open(method, url, true);
  } else if (typeof XDomainRequest != "undefined") {
    // XDomainRequest for IE.
    xhr = new XDomainRequest();
    xhr.open(method, url);
  } else {
    // 不支持CORS.
    xhr = null;
  }
  return xhr;
}

/**
    * 上传文件
    * @upload   object      file类型input,页面调用传this
    * @isAPK    boolean     true:apk文件 false:图片
    * @isNewAPK boolean     true:新建APK 在升级apk使用
    * @isIcon   boolean     true:apk图标 false:apk海报
 */
function uploadFile(upload,isAPK,isNewAPK,isIcon,pictureType) {

    var upload = $(upload);
    var oldFileName = upload.next('.bootstrap-filestyle').children(':text').val();

    var fileObj = upload.get(0).files[0];
    if(!fileObj){
        return;
    }
    var resultInfo = $('#uploadResultInfo');
    var form = new FormData();
    if(isAPK)
    {
        if(isNewAPK==true)
        {
            form.append("uploadType", 0);
        }
        else if(isNewAPK==2){
            form.append("uploadType", 2);
            form.append("applicationId",$("input:hidden[name='applicationId']").val());
            form.append("applicationFileId",$("input:hidden[name='applicationFileId']").val());
        }
        else if(isNewAPK==3){
            form.append("uploadType", 3);
            form.append("applicationId",$("input:hidden[name='applicationId']").val());
            form.append("applicationFileId",$("input:hidden[name='applicationFileId']").val());
        }
        else{
            form.append("uploadType", 1);
            form.append("applicationId",$("input:hidden[name='applicationId']").val());
            form.append("applicationFileId",$("input:hidden[name='oldApplicationFileId']").val());
        }

    }
    else
    {
        if(pictureType){
            form.append("pictureType", pictureType);
        }else{
            form.append("pictureType", isIcon?19:20);
        }


        //暂不支持预览图片
        // Get a reference to the fileList
        // var files = upload.get(0).files;
        // // If no files were selected, or no FileReader support, return
        // if (!files.length || !window.FileReader) return;

        // // Only proceed if the selected file is an image
        // if (/^image/.test( files[0].type)){
        //     // Create a new instance of the FileReader
        //     var reader = new FileReader();
        //     // Read the local file as a DataURL
        //     reader.readAsDataURL(files[0]);
        //     // When loaded, set image data as background of div
        //     reader.onloadend = function(event){

        //         var pic_preview = $('<div class="preview"></div>');
        //         pic_preview.css("background-image", "url("+event.target.result+")")
        //             .css("position","absolute").css("left","200px");
        //         upload.parent().append(pic_preview);

        //     }
        // }
    }
    form.append("fileItem", fileObj);
    form.append('accessToken',window.app_token);
    var api = isAPK?url+'upload_apkfile':url+'upload_picfile';
    var xhr = new createCORSRequest("post",api);

    xhr.addEventListener("load",function(){
        var json = $.parseJSON(xhr.response);
        if(isAPK)
        {
            switch(json['resultCode'])
            {
                case 0:
                    resultInfo.text('上传成功');
                    break;
                case 1:
                    if(json['errMsg']){
                        resultInfo.text('上传失败， '+json['errMsg']);
                    }else{
                        resultInfo.text('上传失败');
                    }
                    break;
                case 2:
                    resultInfo.text('非APK文件');
                    break;
                case 3:
                    resultInfo.text('包名已存在');
                    break;
                case 4:
                    resultInfo.text('包名或签名错误，跟上一版本不一致');
                    break;
                case 5:
                    resultInfo.text('升级版本号小于或等于前一版本');
                    break;
                case 6:
                    resultInfo.text('版本编码为空');
                    break
                case 7:
                    resultInfo.text('包名存在于应用黑名单中');
                    break;
                case 8:
                    resultInfo.text('版本号小于前一版本');
                    break;
            }
        }
        else
        {
            switch(json['resultCode'])
            {
                case 0:
                    resultInfo.text('上传成功');
                    break;
                case 1:
                    if(json['errMsg']){
                        resultInfo.text(json['errMsg']);
                    }
            }
        }
        resultInfo.removeClass().addClass('resultInfo');
        if(json['resultCode']!=0)
        {
            resultInfo.addClass('bg-danger');
            upload.next('.bootstrap-filestyle').children(':text').val(oldFileName);
            upload.parents('.row').eq(0).addClass('has-error').find('.help-block').show();
            return;
        }
        else
        {
            resultInfo.addClass('bg-success');
            upload.parents('.row').eq(0).removeClass('has-error').find('.help-block').hide();
            upload.eq(0).removeAttr('required');
        }
        var inputs = upload.parent().children('input:hidden');
        if(isAPK)
        {
            $('#versionNo').val(json['versionNo']);
            $('#apk_upload').next().find('input').val(json['fileName']);
            for(var i=0;i<inputs.length;i++)
            {
                if(inputs[i].name in json)
                {
                    if(inputs[i].name!='meta'){
                        inputs[i].value = json[inputs[i].name];
                        if(inputs[i].name == 'fileName'){
                            _newFiles.apk.push(inputs[i].value);
                        }
                    }
                    else{
                        var metaTemp = json[inputs[i].name];
                        for(var j=0;j<metaTemp.length;j++){
                            var language = metaTemp[j];
                            var appName = language.appName;
                            var languageId = language.languageId;
                            var picUrl = language.pictures[0].pictureUrl;
                            var appDesc = language.appDesc;
                            var needNew = true;
                            $("[name='defaultTitle']").val(appName);
                            $('.languageDiv').each(function(){
                                var $tmp = $(this);
                                if(languageId == $tmp.find("[name='languageId[]']").val()){

                                    var $appName = $tmp.find("[name='applicationName[]']");
                                    if($appName.val()){
                                        if($appName.val() != appName && confirm("文件名已存在是否替换?")){
                                            $appName.val(appName);
                                            $("[name='defaultTitle']").val(appName);
                                        }
                                    }
                                    else
                                    {
                                        $appName.val(appName);
                                        $("[name='defaultTitle']").val(appName);
                                    }

                                    var $post = $tmp.find("[name='poster[]']");
                                    var picBaseName = picUrl.substring(picUrl.lastIndexOf("/")+1);
                                    if($post.val()){
                                        if( $post.val() != picBaseName && confirm("应用图标已存在是否替换?")){
                                            $post.val(picBaseName);
                                            $tmp.find(".previewImg")
                                                .children('img').eq(0)
                                                .attr('src',picUrl)
                                                .parent().show();
                                            $tmp.find("input[type='file']").eq(0).removeAttr('required');
                                            $tmp.find('.bootstrap-filestyle').eq(0).children(':text').val(picBaseName);
                                        }
                                        $tmp.find("input[type='file']").eq(0).removeAttr('required');
                                    }else
                                    {
                                        $post.val(picBaseName);
                                        $tmp.find(".previewImg")
                                            .children('img').eq(0)
                                            .attr('src',picUrl)
                                            .parent().show();
                                        $tmp.find('.bootstrap-filestyle').eq(0).children(':text').val(picBaseName);
                                        $tmp.find("input[type='file']").eq(0).removeAttr('required');
                                    }
                                    var $desc = $tmp.find("[name='applicationDesc[]']");
                                    if($desc.val()){
                                        if($desc.val() != appDesc && confirm("应用描述已存在是否替换?")){
                                            $desc.val(appDesc);
                                        }
                                    }else{
                                        $desc.val(appDesc);
                                    }
                                    needNew = false;
                                    return false;
                                }
                            });

                            if(needNew){
                                addLanguageAjax(languageId,appName,picUrl,appDesc);
                            }
                            break;
                        }
                    }

                }
            }

        }
        else
        {
            var tmp = json['fileUrl'].split("/");
            inputs[0].value = tmp[tmp.length-1];
            _newFiles.img.push(inputs[0].value)
            if(upload.parent().nextAll('.previewImg').length)
            {
                upload.parent().nextAll('.previewImg').show().children('img').attr('src',json['fileUrl']);
            }
        }
    });

    xhr.addEventListener("error",function(XMLHttpRequest, textStatus, errorThrown)
                        {
                            console.log(XMLHttpRequest.status);
                            console.log(XMLHttpRequest.readyState);
                            console.log(textStatus);
                            return false;
                        });
    xhr.addEventListener("timeout",function(){console.log("time out")});
    xhr.upload.addEventListener("progress",function(evt){
        $('#myModal').modal({backdrop: 'static', keyboard: false});
        $('#myModal .modal-title').html('上传');
        var $pb = $('.progress-bar.progress-bar-danger');
        var p = Math.round(evt.loaded / evt.total * 100);
        $('#progressPercentage').html(p+'%');
        $pb.attr('data-transitiongoal', p);
        $('.progress .progress-bar').progressbar();
    }, false);
    $('#myModal').on('hide.bs.modal', function () {
        xhr.abort();
    });
    xhr.send(form);
}

//保持old数据,创建appTag
function creat_old_appTag(old_category,accessToken,old_appTag){
    var active_txt;
    var checked_txt;

    $.ajax({
        type:'GET',
        url: config.APPSTORE.das_url+'/category/get_tag',
        data:{'categoryId':old_category,'accessToken':accessToken},
        beforeSend:function(){
            $(".btn-group.appTag").empty();
        },
        success:function(data){
            if(!data.resultCode && data.data && data.data.length > 0){
                var html = "";
                for(var i=0;i<data.data.length;i++){
                    var every=data.data[i];
                    if($.inArray(every.tagDefineId, old_appTag) !== -1){
                        active_txt = "active";
                        checked_txt = "checked";
                    }else{
                        active_txt = "";
                        checked_txt = "";
                    }
                    html += '<label class="btn btn-primary '+ active_txt +'">'+
                                '<input type="checkbox" name="appTag[]" value="' + every.tagDefineId + '" autocomplete="off" '+ checked_txt +'> '+ every.tag +
                            '</label>';
                }
                $(".btn-group.appTag").empty().append(html);
            }else{
                $(".btn-group.appTag").append("<div style='padding-top:7px;'>当前分类未配置标签，请与管理员联系！</div>");
            }
        }
    });
}

function deleteFile(isAPK,fileName,callback)
{
    var api = url+'del_file';
    $.ajax({
        async:true,
        data: { 'fileType':isAPK?0:1,
                'fileNames':fileName,
                'accessToken':window.app_token},
        type:'post',
        url:api,
        success:function(){if(callback)callback()},
        error:function(XMLHttpRequest, textStatus, errorThrown)
                        {
                            console.log(XMLHttpRequest.status);
                            console.log(XMLHttpRequest.readyState);
                            console.log(textStatus);
                            return false;
                        }
    });
}


function removeLanguage(btn){
    $(btn).parent().parent().parent(".panel").remove();
}


function addLanguageAjax(languageId,appName,postUrl,appDesc,callback,appType){
        $.ajax({
            type: "GET",
            url: config.AJAX_URL.addLanguageAjax_url,
            data: {languageId:languageId,
                   appName:appName,
                   postUrl:postUrl,
                   appDesc:appDesc,
                   appType:appType},
            success: function(msg){
                $('#addCont').append(msg);
                if(callback) callback();
                //var $selectEle = $('select[name="languageId[]"]');
                //$selectEle.last().get(0).selectedIndex = ($selectEle.eq($selectEle.length - 2).get(0).selectedIndex + 1) % $selectEle.length;

                var $instance = $('.dragsortBox');
                $instance.dragsort("destroy");
                $instance.dragsort({
                    dragSelector: ".dragsortBox .addSnap",
                    dragBetween: true,
                    dragEnd: function () {
                        $(this).find('.dragsortBox .addSnap').removeClass('drag');
                        $(this).parent().find("input[name^='snap'][name$='[]']").each(function(i,e){
                            $(e).attr('name','snap'+i+'[]');
                        });
                    },
                    placeHolderTemplate: '<div class="form-group col-md-4 addSnap drag"></div>'
                });
            }
        });
    }

function addSnap(btn){
    var $divParent = $(btn).parent().parent().parent();
    var $restSnaps = $divParent.nextAll(".addSnap");
    if($restSnaps.length == (snap_max_num-1) ){ //限制
        alert('截图最多可以上传'+snap_max_num+'张');
        return;
    }
    $url = config.AJAX_URL.addSnapAjax_url+'/'+($restSnaps.length+1);
    $.ajax({
        type: "GET",
        url: $url,
        data: "",
        async:false,
        success: function(msg){
            if($restSnaps.length==0){
                $divParent.after(msg);
            }else{
                $restSnaps.eq($restSnaps.length-1).after(msg);
            }
        }
    });
}

function addSnap2(btn){
    var $divParent = $(btn).parent().prev();
    var $restSnaps = $divParent.find(".addSnap");
    if($restSnaps.length == snap_max_num){ //限制
        alert('截图最多可以上传'+snap_max_num+'张');
        return;
    }
    var msg="";
    msg+='<div class="form-group col-md-4 addSnap">'+
        '<div class="uploadfile"><input type="file" class="form-control" onchange="uploadFile(this,false,false,false)" accept="image/gif,image/jpg, image/jpeg, image/png">'+
        '<input type="hidden" name="snap'+$restSnaps.length+'[]" /></div>'+
        '<div class="uploadText">选择文件</div>'+
        '<div class="row form-group col-md-2 previewImg">'+
        '<img src="/image/blank.gif">'+
        '</div>'+
        '<button name="removeSnapBtn" type="button" style="color:#CC3333" class=" btn btn-default" aria-label="Left Align" onclick="javascript:removeSnap2(this)">'+
        '<span class="glyphicon glyphicon-minus-sign" aria-hidden="true"></span>'+
        '</button>'+
        '</div>'
    if($restSnaps.length==0){
        $divParent.append(msg);
    }else{
        $restSnaps.eq($restSnaps.length-1).after(msg);
    }

}

function removeSnap(btn){
    //var $uploaded = $(btn).parent().prev().find('input:hidden');
    /*if($uploaded.attr('name') == 'snap1[]'){
        var $snap2Div = $(btn).parents('.addSnap').eq(0).next();
        if($snap2Div.length == 1){
            var $snap2 = $snap2Div.find('input:hidden')
            $snap2.attr('name','snap1[]');
        }
    }*/
    var snapParent= $(btn).parents('.panel-body');
    $(btn).parents('.form-group.addSnap').remove();
   /* $("input[name^='snap'][name$='[]']").each(function(i,e){    //alert(i);
        $(e).attr('name','snap'+i+'[]');
    });*/
    snapParent.find("input[name^='snap'][name$='[]']").each(function(i,e){
        $(e).attr('name','snap'+i+'[]');
    });
}

function removeSnap2(btn){
    var snapParent= $(btn).parents('.dragsortBox');
    if(snapParent.find('.addSnap').length<=1){
        alert("截图必须上传");
        return false;
    }
    $(btn).parents('.form-group.addSnap').remove();
    snapParent.find("input[name^='snap'][name$='[]']").each(function(i,e){
        $(e).attr('name','snap'+i+'[]');
    });
}

function sbm(id){

    var checkboxLength=$('.btn-group.control-mode :checked').length;

    var fileName=$("input[name='fileName']");
    var cat=$("#cat");
    var releaseNote = $("textarea[name='releaseNotes']");
    //var defaultTitle = $("input[name='defaultTitle']");
    // var appTag = $("input[name='appTag']"); //标签改为选择，这里暂时去掉

    /*if(!defaultTitle.val()){
        scrollTopTo("input[name='defaultTitle']",100);
    }else */
    if(!cat.val()){
        scrollTopTo("#cat",100);
    // }else if(!appTag.val()){
    //     scrollTopTo(".tag-editor",100);
    }else if(!fileName.val()){
        scrollTopTo("#apk_upload",100);
    }else if(!releaseNote.val()){
        scrollTopTo("textarea[name='releaseNotes']",100);
    }
    if(checkboxLength == 0){
        scrollTopTo("#cat",100);
        window.isControlMode=false;
       /* $('#appForm').submit(function(){
            e.preventDefault();
        });*/
    }else{
        window.isControlMode=true;
    }
    $('#type').val(id);
}
/**
 *
 * @param selector
 * @param top
 * @param operator 1:+ 0:-
 */
function scrollTopTo(selector,top,operator){
    var scrollTop ='';
    if(operator){
        scrollTop=$(selector).offset().top+top;
    }else{
        scrollTop=$(selector).offset().top-top;
    }
    $("html,body").animate({scrollTop:scrollTop},1000);
}
$(document).ready()
{

    if($("input[name='fileName']:hidden").val())
    {
        window._oldFiles.apk.push($("input[name='fileName']:hidden").val());
        //console.log('old apk name:'+$("input[name='fileName']:hidden").val());
    }

    $("input[name^='post']:hidden").each(function(){
        if($(this).val())
        {
            window._oldFiles.img.push($(this).val());
        }
    });

    $("input[name^='snap']:hidden").each(function(){
        if($(this).val())
        {
            window._oldFiles.img.push($(this).val());
        }
    });

   /* window.onbeforeunload = function(){
        //console.log('开始删除上传过的文件');
        if(window._form_submit_success){
            var apk_fileName = $("input[name='fileName']:hidden").val();
            for(var i=0;i<_newFiles.apk.length;i++){
                if(apk_fileName == _newFiles.apk[i]){
                    _newFiles.apk.remove(i);
                    break;
                }
            }
            if(window._oldFiles.apk.length == 1 && window._oldFiles.apk[0] != apk_fileName){
                //console.log('删除旧的apk:'+window._oldFiles.apk[0]);
                var apk_fileName = window._oldFiles.apk[0];
                apk_fileName = apk_fileName.substring(apk_fileName.lastIndexOf("/")+1);
                deleteFile(true,apk_fileName);
            }

            $("input[name^='post']:hidden").each(function(){
                for(var i=0;i<_newFiles.img.length;i++){
                    if($(this).val() == _newFiles.img[i]){
                        _newFiles.img.remove(i);
                        break;
                    }
                }
                for(var i=0;i<_oldFiles.img.length;i++){
                    if($(this).val() == _oldFiles.img[i]){
                        _oldFiles.img.remove(i);
                    }
                }
            });

            $("input[name^='snap']:hidden").each(function(){
                for(var i=0;i<_newFiles.img.length;i++){
                    if($(this).val() == _newFiles.img[i]){
                        _newFiles.img.remove(i);
                        break;
                    }
                }
                for(var i=0;i<_oldFiles.img.length;i++){
                    if($(this).val() == _oldFiles.img[i]){
                        _oldFiles.img.remove(i);
                    }
                }
            });

            for(var i=0;i<_oldFiles.img.length;i++)
            {
                //console.log('----删除旧图片:'+_oldFiles.img[i]);
                deleteFile(false,_oldFiles.img[i]);
            }
        }
        for(var i=0;i<_newFiles.apk.length;i++)
        {
            //console.log('----删除新的apk:'+_newFiles.apk[i]);
            deleteFile(true,_newFiles.apk[i]);
        }
        for(var i=0;i<_newFiles.img.length;i++)
        {
            //console.log('----删除新的图片:'+_newFiles.img[i]);
            deleteFile(false,_newFiles.img[i]);
        }
        _newFiles = null;
        _oldFiles = null;
        //console.log('删除上传过的文件结束');
    }
*/

    $(".newfilestyle").filestyle({size: "sm"});
    // $('.hastip').tooltipsy({
    //     className: 'bubbletooltip_tip',
    //     offset: [0, 10],
    //     show: function (e, $el) {
    //         $el.fadeIn(100);
    //     },
    //     hide: function (e, $el) {
    //         $el.fadeOut(1000);
    //     }
    // });

    $('.checkbox-group').on('change', function (e) {
      var $group = $(".checkbox-group");
      var $checkbox = $(e.target);
      $group.not($checkbox).prop('required', !$checkbox.prop('checked'));
    });


    $("#myModal").on('hidden.bs.modal', function(){
        var $pb = $('.progress-bar.progress-bar-danger');
        $('#progressPercentage').html(0+'%');
        $pb.attr('data-transitiongoal', 0);
        $pb.removeAttr('aria-valuenow');
        $pb.removeAttr('style');
        $('#uploadResultInfo').removeClass().html('');
    });

    $('.add-icon').click(function(){
        var $defaultL = $('select[name="defaultLanguageId"]');
        var languageName = $defaultL.find("option:selected").text();
        var languageId = $defaultL.val();
        var appType = $(this).data("apptype");
        //判断多语言重复
        var $language_ary = $('input[name="languageId[]"]');
        var $language_val=[];
        for(var i=0;i<$language_ary.length;i++){
            if (languageId==$language_ary[i].value){
                alert("多语言重复,请重新选择!");
                return false;
            }
        }

        addLanguageAjax(languageId,null,null,null,null,appType)
    });

    //应用名称与中文名称关联
    $("input[id='appNameCN']").on("input",function(){
        var name=$(this).val();
        $("[name='defaultTitle']").val(name);
    });

    //get token
    var promise = $.ajax({
        type:"GET",
        url: config.AJAX_URL.getAppTokenAjax_url,
        data: ""
    });

    promise.done(function(msg){
        window.app_token = msg;
        if(window.required_picture_type){
            //取得应用图标的校验信息
            $.ajax({
                type:'GET',
                url: config.APPSTORE.dev_url+'/get-picture-type',
                data:{'type':19,'accessToken':window.app_token},
                success:function(msg){
                     $('.iconInfo').html(_parse_picture_type(msg));
                }
            });

            //取得应用海报的校验信息
            $.ajax({
                type:'GET',
                url: config.APPSTORE.dev_url+'/get-picture-type',
                data:{type:20,accessToken:window.app_token},
                success:function(msg){
                    $('.postInfo').html(_parse_picture_type(msg));
                }
            });

            //取得推荐海报的校验信息
            $.ajax({
                type:'GET',
                url: config.APPSTORE.dev_url+'/get-picture-type',
                data:{type:89,accessToken:window.app_token},
                success:function(msg){
                    $('.recommend-poster-info').html(_parse_picture_type(msg));
                }
            });

            //取得推荐海报2的校验信息
            $.ajax({
                type:'GET',
                url: config.APPSTORE.dev_url+'/get-picture-type',
                data:{type:49,accessToken:window.app_token},
                success:function(msg){
                    $('.wideScreenRecommend-info').html(_parse_picture_type(msg));
                }
            });
        }
    });


    function _parse_picture_type(msg){
        if(msg['resultCode'] == 0){
            var picSizeInfo = '图片大小必须小于'+msg['data']['pictureSize']+'KB,';
            var picFormatInfo = '格式必须为'+msg['data']['fileFormat']+'.';
            switch(msg['data']['checkMode']){
                case '0':
                    var picLenInfo = '图片尺寸必须为高: '+msg['data']['pictureWidth']+',宽: '+msg['data']['pictureLength'];
                    break;
                case '1':
                    var picLenInfo = '图片尺寸不超过高: '+msg['data']['pictureWidth']+',宽: '+msg['data']['pictureLength'];
                    break;
                case '2':
                    var picLenInfo = '';
                    break;
                case '3':
                    var picLenInfo = '图片尺寸最小高度: '+msg['data']['minPictureHeight']+',最小宽度: '+msg['data']['minPictureWidth']
                                    +',最大高度:'+msg['data']['maxPictureHeight']+',最大宽度: '+msg['data']['maxPictureWidth'];
                    break;
            }
            return picSizeInfo+'\n'+picFormatInfo+'\n'+picLenInfo;
        }
    }

    //标签选择1~5个
    $(document).on("click", ".btn-group.appTag label", function(){
        var $this = $(this);
        setTimeout(function(){
            var len = $("input[name^=appTag]:checked").length;
            if(len>5 || len<1 ){
                if(len<1){
                    $this.addClass("active");
                    $this.find("input").prop("checked",true);
                }else{
                    $this.removeClass("active");
                    $this.find("input").prop("checked",false);
                }
                alert("请选择[1~5]个标签");
                return false;
            }
        },100)
    })

    var appFormBtn_disabled = false;
    $('#appForm').validator().on('submit',function(e)
    {
        if(appFormBtn_disabled){
            return false;
        }
        if($("input[name='freeFlag']:checked").val()==0 || $("#priceBox").is(":visible")){
            var $price = $("input[name='price']");
            var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
            if ($price.val() == "") {
                alert("价格不能为空！");
                scrollTopTo("input[name='price']",100);
                $price.focus();
                return false;
            } else {
                if ($price.val()>1000) {
                    alert("价格不能大于1000！");
                    scrollTopTo("input[name='price']",100);
                    $price.focus();
                    return false;
                }
                if (!reg.test($price.val())) {
                    alert("价格必须为保留两位小数的正数！");
                    scrollTopTo("input[name='price']",100);
                    $price.focus();
                    return false;
                }
            }
        }
        if(!window.isControlMode){
            alert('至少选一项操作方式');
            return false;
        }
        if($('.btn-group.appTag :checked').length == 0){
            alert('至少选一个标签');
            scrollTopTo(".btn-group.appTag",100);
            return false;
        }
        if (e.isDefaultPrevented())
        {
            // handle the invalid form...
            if(!$('#cat').val()){
                $('#cat').closest('.form-group').addClass('has-error');
            }
        }
        else
        {
            // everything looks good!
            var $selectEle = $('select[name="languageId[]"]');
            var selectedHash = {};
            for(var i=0;i<$selectEle.length;i++)
            {
                var selectedOption = $selectEle.eq(i)
                        .find("option:selected").val();
                if(selectedHash[selectedOption])
                {
                    alert("多语言重复,请重新选择!");
                    return false;
                }
                else
                {
                    selectedHash[selectedOption] = true;
                }
            }

            //判断多语言重复
            var $language_ary = $('input[name="languageId[]"]');
            var $language_val=[];
            for(var i=0;i<$language_ary.length;i++){
                $language_val.push($language_ary[i].value);
            }
            var nary=$language_val.sort();
            for(var i=0;i<$language_ary.length;i++){
                if (nary[i]==nary[i+1]){
                    alert("多语言重复,请重新选择!");
                    return false;
                }
            }

            var defaultLanguageVal =  $('select[name="defaultLanguageId"]').eq(0).find("option:selected").val();


            /*if(!selectedHash.hasOwnProperty(defaultLanguageVal)){
                alert("默认语言不匹配,请重新选择!");
                return false;
            }*/


            if(!($("input:hidden[name='fileName']").val()))
            {
                alert("请上传APK文件");
                return false;
            }
            var $post = $("input:hidden[name='poster[]']");

            for(var i=0;i<$post.length;i++){
                if(!($post.eq(i).val())){
                    alert("请上传应用图标");
                    return false;
                }
            }

            //判断最少上传截图数
            if(snap_min_num){
                var $snap=[];
                var str="";
                for(var i=0;i<snap_min_num;i++){
                    $snap[i] = $("input:hidden[name='snap"+i+"[]']");
                    if(str == ""){
                        str = "typeof($snap["+i+"].eq(i).val()) == 'undefined' || (!$snap["+i+"].eq(i).val())";
                    }else{
                        str = str + " || " + "typeof($snap["+i+"].eq(i).val()) == 'undefined' || (!$snap["+i+"].eq(i).val())";
                    }
                }
                for(var i=0;i<$snap[0].length;i++){
                    if(eval(str)){
                        alert("请至少上传"+snap_min_num+"张截图");
                        return false;
                    }
                }

            }

            /*var $snap0 = $("input:hidden[name='snap0[]']");
            var $snap1 = $("input:hidden[name='snap1[]']");
            var $snap2 = $("input:hidden[name='snap2[]']");*/
            var $recommend_poster = $("input:hidden[name='recommend_poster[]']");
            var $wideScreenRecommend = $("input:hidden[name='wideScreenRecommend[]']");

           /* if($snap0.length % (i+1) != 0){

            }*/
            /*for(var i=0;i<$snap0.length;i++){
                if(typeof($snap0.eq(i).val()) == 'undefined' || typeof($snap1.eq(i).val()) == 'undefined' || typeof($snap2.eq(i).val()) == 'undefined' || (!$snap0.eq(i).val()) || (!$snap1.eq(i).val()) || (!$snap2.eq(i).val())){
                    alert("请至少上传三张海报");
                    return false;
                }
            }*/

            for (var i=0;i<$recommend_poster.length;i++){
                if(!$recommend_poster.eq(i).val()){
                    alert("请上传推荐海报");
                    return false;
                }
            }
            for (var i=0;i<$wideScreenRecommend.length;i++){
                if(!$wideScreenRecommend.eq(i).val()){
                    alert("请上传推荐海报2");
                    return false;
                }
            }


           /* for(var i=0;i<$snap2.length;i++){
                if(!($snap0.eq(i).val()) || !($snap1.eq(i).val()) || !($snap2.eq(i).val())){
                    alert("请至少上传三张海报");
                    return false;
                }
            }*/
            /*var $defaultLanguageID = $("select[name='defaultLanguageId']");
            if($defaultLanguageID.val() != 0){
                alert('默认语言必须是中文');
                return false;
            }*/

            var $languageID = $("select[name='languageId[]']");
            var $isChinese = 0;
            for(var i=0;i<$languageID.length;$i++){
                if($languageID[i].val() == '0'){
                    $isChinese=1
                }
            }
            /*if(!$isChinese){
                alert('多语言中必修有中文语言！');
                return false;
            }*/

            //处理海报逻辑
            $('.languageDiv').each(function(){
                var $snaps = $(this).find('.addSnap');
                if(snap_max_num){
                    var $snap = $snaps.eq($snaps.length-1).find('input:hidden');
                    for(i=0; i<snap_max_num-$snaps.length; i++){
                        $snap.after("<input type='hidden' name='snap"+($snaps.length+i)+"[]' value=''>");
                    }
                }
                /*
                switch($snaps.length){
                    case 1:
                        var $snap = $snaps.find('input:hidden');
                        $snap.after("<input type='hidden' name='snap1[]' value=''>")
                              .after("<input type='hidden' name='snap2[]' value=''>")
                              .after("<input type='hidden' name='snap3[]' value=''>")
                              .after("<input type='hidden' name='snap4[]' value=''>")
                              .after("<input type='hidden' name='snap5[]' value=''>");
                        break;
                    case 2:
                        var $snap = $snaps.eq(1).find('input:hidden');
                        $snap.after("<input type='hidden' name='snap2[]' value=''>")
                            .after("<input type='hidden' name='snap3[]' value=''>")
                            .after("<input type='hidden' name='snap4[]' value=''>")
                            .after("<input type='hidden' name='snap5[]' value=''>");
                        break;
                    case 3:
                        var $snap = $snaps.eq(2).find('input:hidden');
                        $snap.after("<input type='hidden' name='snap3[]' value=''>")
                            .after("<input type='hidden' name='snap4[]' value=''>")
                            .after("<input type='hidden' name='snap5[]' value=''>");
                        break;
                    case 4:
                        var $snap = $snaps.eq(3).find('input:hidden');
                        $snap.after("<input type='hidden' name='snap4[]' value=''>")
                             .after("<input type='hidden' name='snap5[]' value=''>")
                        break;
                    case 5:
                        var $snap = $snaps.eq(4).find('input:hidden');
                        $snap.after("<input type='hidden' name='snap5[]' value=''>");
                        break;
                    default :
                        break;
                }
                */
            });

            window._form_submit_success = true;
            appFormBtn_disabled = true;
        }
    });

    $("input[name='price']").blur(function(){
        //判断价格
        var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if ($(this).val() == "") {
            alert("价格不能为空！");
            return false;
        } else {
            if ($(this).val()>1000) {
                alert("价格不能大于1000！");
                return false;
            }
            if (!reg.test($(this).val())) {
                alert("价格必须为保留两位小数的正数！");
                return false;
            }
        }
    });

    $("textarea[name='releaseNotes']").blur(function(){
        if($(this).val()){
            //$(this).next('.help-block').hide();
            $(this).parent().removeClass('has-error');
        }
        else{
            $(this).next('.help-block').show();
        }
    });

    $('input[name="applicationName\\[\\]"]').blur(function(){
        if($(this).val()){
            if($(this).val().length > 20){
                //$(this).next('.help-block').show();
                $(this).next('.help-block').find('.list-unstyled li').html('字符不能超过20个');
                $(this).parent().addClass('has-error');
            }else{
                $(this).parent().removeClass('has-error');
                $(this).next('.help-block').find('.list-unstyled li').html('请填写应用名称');
                //$(this).next('.help-block').hide();
            }
        }

    });

    $('textarea[name="applicationDesc\\[\\]"]').blur(function(){
        if($(this).val()){
            //$(this).next('.help-block').hide();
            $(this).parent().removeClass('has-error');
        }
        else{
            $(this).next('.help-block').show();
        }
    });

    $('#cat').blur(function(){
        if(!$(this).val()){
            $(this).parent().addClass('has-error');
        }
        else{
            $(this).parent().removeClass('has-error');
        }
    });

    //联运游戏表单验证
    var applyBtn_disabled = false;
    $('#tv_dock_Form').validator().on('submit',function(e)
    {
        if(applyBtn_disabled){
            return false;
        }
        applyBtn_disabled = true;

    });

    $("#tv_dock_Form input[name='defaultTitle']").blur(function(){
        if($(this).val()){
            $(this).parent().removeClass('has-error');
        }
    });

    $("#saveBtn").click(function(){

        var checkboxLength=$('.btn-group.control-mode :checked').length;
        var defaultTitle = $("input[name='defaultTitle']");
        var fileName=$("input[name='fileName']");
        var cat=$("#cat");
        var releaseNote = $("textarea[name='releaseNotes']");
        // var appTag = $("input[name='appTag']");
        if(!defaultTitle.val()){
            scrollTopTo("input[name='defaultTitle']",100);
            defaultTitle.parent().addClass('has-error');
            return false;
         }else if(!cat.val()){
            scrollTopTo("#cat",100);
            cat.parent().addClass('has-error');
            return false;
        }else if(checkboxLength == 0){
            scrollTopTo("#cat",100);
            alert('至少选一项操作方式');
            return false;
        }else if(!fileName.val()){
            scrollTopTo("#apk_upload",100);
            alert("请上传APK文件");
            return false;
        }else if(!releaseNote.val()){
            scrollTopTo("textarea[name='releaseNotes']",100);
            releaseNote.parent().addClass('has-error');
            return false;
        }

        if($('.btn-group.appTag :checked').length == 0){
            alert('至少选一个标签');
            scrollTopTo(".btn-group.appTag",100);
            return false;
        }

        if(!($("input:hidden[name='fileName']").val()))
        {
            alert("请上传APK文件");
            return false;
        }

        if(!$('#category').val()){
            alert("请选择分类");
            return false;
        }

    });

    $("#applyBtn").click(function(){

        if($("input[name='callBackUrl']").val() != ""){
            var strRegex = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
            if(!$("input[name='callBackUrl']").val().match(strRegex))
            {
                alert("请输入正确的URL");
                return false;
            }
        }

    });

}

