$(function(){
    var setting = {
        check: {
            enable: true,
            chkboxType: {"Y":"", "N":""},
            chkStyle: "radio",
            radioType: "all"
        },
        view: {
            dblClickExpand: false
        },
        data: {
            simpleData: {
                enable: true
            }
        },
        callback: {
            beforeClick: beforeClick,
            onCheck: onCheck,
            onClick: zTreeOnClick
        }
    };

    function zTreeOnClick(event, treeId, treeNode) {
        alert(treeNode.tId + ", " + treeNode.name);
    };
    function beforeClick(treeId, treeNode) {
        var zTree = $.fn.zTree.getZTreeObj("cat_tree");
        zTree.checkNode(treeNode, !treeNode.checked, null, true);
        return false;
    }

    function onCheck(e, treeId, treeNode) {
        var zTree = $.fn.zTree.getZTreeObj("cat_tree"),
                nodes = zTree.getCheckedNodes(true),
                v = "";
        var id_str="";
        for (var i=0, l=nodes.length; i<l; i++) {
            v += nodes[i].name + ",";
            if(i == l-1){
                id_str+=nodes[i].id;
            }else{
                id_str+=nodes[i].id+',';
            }
        }

        if (v.length > 0 ) v = v.substring(0, v.length-1);
        var cat = $("#cat");
        cat.attr("value", v);
        $("#category").attr('value',id_str);
        //get-tags
        $.ajax({
            type:'GET',
            url: config.APPSTORE.das_url+'/category/get_tag',
            data:{'categoryId':id_str,'accessToken':window.app_token},
            beforeSend:function(){
                $(".btn-group.appTag").empty();
            },
            success:function(data){
                if(!data.resultCode && data.data && data.data.length > 0){
                    var html = "";
                    for(var i=0;i<data.data.length;i++){
                        var every=data.data[i];
                        html += '<label class="btn btn-primary">'+
                                    '<input type="checkbox" name="appTag[]" value="' + every.tagDefineId + '" autocomplete="off"> '+ every.tag +
                                '</label>';
                    }
                    $(".btn-group.appTag").empty().append(html);
                }else{
                    $(".btn-group.appTag").append("<div style='padding-top:7px;'>当前分类未配置标签，请与管理员联系！</div>");
                }
            }
        });
        cat.parent().removeClass('has-error');
    }

    function showMenu() {
        var cat = $("#cat");
        var catOffset = $("#cat").offset();
        $("#menuContent").css({left:catOffset.left + "px", top:catOffset.top + cat.outerHeight() + "px"}).slideDown("fast");
        $("body").bind("mousedown", onBodyDown);
    }
    function hideMenu() {
        $("#menuContent").fadeOut("fast");
        $("body").unbind("mousedown", onBodyDown);
    }
    function onBodyDown(event) {
        if (!(event.target.id == "menuBtn" || event.target.id == "cat" || event.target.id == "menuContent" || $(event.target).parents("#menuContent").length>0)) {
            hideMenu();
        }
    }
    var curPath = window.document.location.href;
    var pathName= window.document.location.pathname;

    $('#cat').click(function(){
        var id =$(this).val();
        var cat_name = $("input[name='category_name']").val();
        var mixed=category;
        //$("input[name='category_name']").attr('value','');
        //$("input[name='category']").attr('value','');
        if(mixed['data'].length > 0){
            nNodes=[];
            $.each(mixed['data'],function(index,element){
                //tempOBJ={id:element.categoryId,pId:element.parentCategoryId,name:element.categoryName,open:true,nocheck:true};
                tempOBJ={id:element.categoryId,name:element.categoryName,open:true,nocheck:false};
                if(window.checked || $('#category').val()){
                    var tempArray=[];
                    if($('#category').val()){
                        tempArray=$('#category').val().split(',');
                    }else{
                        tempArray=window.checked.split(',');
                    }

                    for(i=0;i<tempArray.length;++i){
                        if(tempArray[i] == tempOBJ.id){
                            tempOBJ.checked=true;
                        }
                    }
                }
                nNodes.push(tempOBJ);
                /*
                if(element.subCategorys.length > 0){
                    $.each(element.subCategorys,function(i,e){
                        subOBJ={id:e.categoryId,pId:e.parentCategoryId,name:e.categoryName};
                        if(window.checked || $('#category').val()){
                            var tempArray=[];
                            if($('#category').val()){
                                tempArray=$('#category').val().split(',');
                            }else{
                                tempArray=window.checked.split(',');
                            }

                            for(i=0;i<tempArray.length;++i){
                                if(tempArray[i] == subOBJ.id){
                                    subOBJ.checked=true;
                                }
                            }
                        }
                        nNodes.push(subOBJ);
                    });
                }*/
            });

            $.fn.zTree.init($("#cat_tree"), setting, nNodes);

            showMenu();
        }
    });

});